<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Posts" size="large" />
    <div class="flex flex-wrap">
      <!-- Row -->
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'GoDetectIcon-sm-min.png',
            title: 'GoDetect Version 8',
            author: 'Clint Guymon',
            date: 'Nov 12th, 2021',
            category: 'Testing',
            link: '/GoDetect8',
            preview:
              'The automatic detection system used for sensitivity testing has been upgraded [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'Brush-and-Scoop-min.png',
            title: 'Conductive Plastic Tools',
            author: 'Jared Teter',
            date: 'May 20th, 2020',
            category: 'Principles of Explosives Safety',
            link: '/conductive-plastic-tools',
            preview:
              'External stimulus such as electrostatic discharge (ESD), impact, or friction [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'mulwala-min.jpg',
            title: 'Mulwala Redevelopment Project (MRP)',
            author: 'Jared Teter',
            date: 'January 2nd, 2020',
            category: 'Hazards Analysis',
            link: '/mrp',
            preview: 'SMS was involved in a major project in South East [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'PlaceImpact-min.jpg',
            title: 'Understanding the Nature of Explosives',
            author: 'Jared Teter',
            date: 'August 27th, 2019',
            category: 'Principles of Explosives Safety',
            link:
              '/fundamental-principles-of-explosives-safety-understanding-the-nature-of-explosives',
            preview:
              'Understanding the nature of energetic material is crucial when storing, [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'DHAOHA-min.png',
            title: 'Process Hazards Analysis (PHA) Flow',
            author: 'Jared Teter',
            date: 'May 30th, 2019',
            category: 'Hazards Analysis, Principles of Explosives Safety',
            link:
              '/fundamental-principles-of-explosives-safety-thorough-accurate-process-hazards-analysis-pha',
            preview:
              'A process hazards analysis (PHA) is a fundamental part to [...]'
          }"
        />
      </div>
      <!-- New Row -->
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            title: 'The 3 Most Common Facility Siting Errors',
            author: 'Jared Teter',
            date: 'October 29th, 2018',
            category: 'Facility Siting',
            link: '/the-3-most-common-facility-siting-errors',
            preview:
              'At SMS, we review, prepare, and consult on site plans [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8">
        <BlogPostCard
          :details="{
            img: 'Arch-Smooth-min.png',
            title: 'Process Hazards Analysis: The Keystone of Process Safety',
            author: 'Jared Teter',
            date: 'October 29th, 2018',
            category: 'Hazards Analysis',
            link: '/process-hazards-analysis-the-keystone-of-process-safety',
            preview: 'SMS was involved in a major project in South East [...]'
          }"
        />
      </div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8"></div>
      <div class="w-full sm:w-1/3 md:w-1/4 mb-6 sm:pr-6 md:pr-8"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BlogPostCard from "@/components/BlogPostCard";

export default {
  metaInfo: {
    title: "Blog",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content: "SMS blog posts."
      }
    ]
  },
  components: {
    TitleComponent,
    BlogPostCard
  }
};
</script>

<style lang="scss">
.about {
}
</style>
